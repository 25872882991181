var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.sfdrStrategy)?_c('ix-section-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('concept.title'),"ix-id":"concept","subtitle":_vm.$t('concept.subtitle')}}),_vm._v(" "),_c('ix-form-input-combobox',{attrs:{"items":_vm.sustainableObjectiveOptions,"value":_vm.sfdrStrategy.concept,"multiple":false,"item-text":"label","item-value":"id","return-object":""},on:{"change":_vm.handleConceptInput}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('ventureIds.title'),"ix-id":"ventureIds","subtitle":_vm.$t('ventureIds.subtitle'),"tooltip":_vm.$t('ventureIds.tooltip')}}),_vm._v(" "),(_vm.redFlagVentures.length > 0)?_c('v-alert',{staticClass:"ma-2",attrs:{"type":"warning","dense":"","outlined":""}},[_vm._v("\n        "+_vm._s(_vm.$t('warningSelectVenture'))+"\n        "),_c('br'),_vm._v(" "),_c('br'),_vm._v(" "),_c('strong',[_vm._v("\n          "+_vm._s(_vm.$t('ventureList', {
              ventureNames: _vm.redFlagVentures
                .map((venture) => venture.name)
                .join(', '),
            }))+"\n        ")])]):_vm._e(),_vm._v(" "),_c('ix-data-table-accounts',{attrs:{"value":_vm.ventureIdsInThisObjective,"filters":{ fundIds: [_vm.fund.id] }},on:{"input":(val) => {
            _vm.$emit('updateStrategy', _vm.sfdrStrategy.id, 'ventureIds', val)
          }}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"ix-id":"sustainabilityIndicatorsMonitoringStrategy","title":_vm.$t('sustainabilityIndicatorsMonitoringStrategy')}}),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_vm.sfdrStrategy.sustainabilityIndicatorsMonitoringStrategy},on:{"input":(val) =>
            _vm.$emit(
              'updateStrategy',
              _vm.sfdrStrategy.id,
              'sustainabilityIndicatorsMonitoringStrategy',
              val,
            )}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"ix-id":"entityResponsibleForMonitoringIndicators","title":_vm.$t('entityResponsibleForMonitoringIndicators')}}),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_vm.sfdrStrategy.entityResponsibleForMonitoringIndicators},on:{"input":(val) =>
            _vm.$emit(
              'updateStrategy',
              _vm.sfdrStrategy.id,
              'entityResponsibleForMonitoringIndicators',
              val,
            )}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-text-master',{attrs:{"ix-id":"isConsideringSourcesAndEstimates","title":_vm.$t('isConsideringSourcesAndEstimates.title'),"subtitle":_vm.$t('isConsideringSourcesAndEstimates.subtitle'),"tooltip":_vm.$t('isConsideringSourcesAndEstimates.tooltip'),"is-mandatory":true,"value":_vm.sfdrStrategy.isConsideringSourcesAndEstimates,"use-text-field":false,"use-binary":""},on:{"input":(val) =>
            _vm.$emit(
              'updateStrategy',
              _vm.sfdrStrategy.id,
              'isConsideringSourcesAndEstimates',
              val,
            )}})],1),_vm._v(" "),(_vm.sfdrStrategy.isConsideringSourcesAndEstimates)?_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"ix-id":"dataSourcesConsidered","title":_vm.$t('dataSourcesConsidered.title'),"is-mandatory":true}}),_vm._v(" "),_c('ix-text-field',{attrs:{"value":_vm.sfdrStrategy.dataSourcesConsidered},on:{"input":(val) =>
            _vm.$emit(
              'updateStrategy',
              _vm.sfdrStrategy.id,
              'dataSourcesConsidered',
              val,
            )}})],1):_vm._e(),_vm._v(" "),(_vm.sfdrStrategy.isConsideringSourcesAndEstimates)?_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"ix-id":"shareOfDataSecondHand","title":_vm.$t('shareOfDataSecondHand'),"is-mandatory":true}}),_vm._v(" "),_c('ix-text-field',{attrs:{"value":_vm.sfdrStrategy.shareOfDataSecondHand},on:{"input":(val) =>
            _vm.$emit(
              'updateStrategy',
              _vm.sfdrStrategy.id,
              'shareOfDataSecondHand',
              val,
            )}})],1):_vm._e(),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[(_vm.showDebugging)?_c('h4',{staticStyle:{"color":"red"}},[_vm._v("\n        metricIds\n        "),_c('v-badge',{directives:[{name:"badge",rawName:"v-badge"}],attrs:{"bordered":"","color":"error","icon":"mdi-lock","overlap":""}})],1):_vm._e(),_vm._v(" "),_c('sfdr-metrics-dialog',{attrs:{"title":_vm.$t('metricIds.title'),"subtitle":_vm.$t('metricIds.subtitle'),"tooltip":_vm.sustainabilityIndictatorDescription,"portfolio-id":_vm.portfolioId,"query":{
          sections: [
            117, // 'IRIS Indicators',
            82, // 'SFDR: PAI Indicators',
          ],
        },"value":_vm.sfdrStrategy.metrics},on:{"input":(val) => _vm.$emit('updateStrategy', _vm.sfdrStrategy.id, 'metricIds', val)}})],1),_vm._v(" "),(_vm.sfdrStrategy.concept)?_c('div',[_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"ix-id":"strategyDescription","title":_vm.$t('strategyDescription.title')}}),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_vm.sfdrStrategy.strategyDescription},on:{"input":(val) =>
              _vm.$emit(
                'updateStrategy',
                _vm.sfdrStrategy.id,
                'strategyDescription',
                val,
              )}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"ix-id":"actionsTaken","title":_vm.$t('actionsTaken.title'),"subtitle":_vm.$t('actionsTaken.subtitle')}}),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_vm.sfdrStrategy.actionsTaken},on:{"input":(val) =>
              _vm.$emit('updateStrategy', _vm.sfdrStrategy.id, 'actionsTaken', val)}})],1),_vm._v(" "),(_vm.fund.fundStrategy === _vm.FundStrategy.Article_9)?_c('v-col',{attrs:{"col":"12"}},[_c('ix-form-input-question',{attrs:{"ix-id":"dueDiligenceProcessDescription","title":_vm.$t('dueDiligenceProcessDescription.title'),"tooltip":_vm.$t('dueDiligenceProcessDescription.tooltip')}}),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_vm.sfdrStrategy.dueDiligenceProcessDescription},on:{"input":(val) =>
              _vm.$emit(
                'updateStrategy',
                _vm.sfdrStrategy.id,
                'dueDiligenceProcessDescription',
                val,
              )}})],1):_vm._e(),_vm._v(" "),_c('v-col',{attrs:{"col":"12"}},[_c('ix-form-input-question',{attrs:{"title":_vm.$t('engagementPolicyWithSpecificInvestmentStrategy.title'),"ix-id":"engagementPolicyWithSpecificInvestmentStrategy","tooltip":_vm.$t('engagementPolicyWithSpecificInvestmentStrategy.tooltip')}}),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_vm.sfdrStrategy.engagementPolicyWithSpecificInvestmentStrategy},on:{"input":(val) =>
              _vm.$emit(
                'updateStrategy',
                _vm.sfdrStrategy.id,
                'engagementPolicyWithSpecificInvestmentStrategy',
                val,
              )}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"ix-id":"isEnvironmentalGoal","title":_vm.$t('isEnvironmentalGoal.title')}}),_vm._v(" "),_c('ix-form-input-small-options',{attrs:{"options":[
            { value: true, label: 'Environmental' },
            { value: false, label: 'Social' },
          ],"value":_vm.sfdrStrategy.isEnvironmentalGoal,"multiple":false,"item-key":"value","item-value":"value","item-text":"label"},on:{"input":(val) =>
              _vm.$emit(
                'updateStrategy',
                _vm.sfdrStrategy.id,
                'isEnvironmentalGoal',
                val,
              )}})],1),_vm._v(" "),(_vm.isArticle9)?_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"ix-id":"isIndexDesignated","title":_vm.$t('specificIndex')}}),_vm._v(" "),_c('ix-form-input-small-options',{attrs:{"options":[
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ],"value":_vm.sfdrStrategy.isIndexDesignated,"multiple":false,"item-key":"value","item-value":"value","item-text":"label"},on:{"input":(val) =>
              _vm.$emit(
                'updateStrategy',
                _vm.sfdrStrategy.id,
                'isIndexDesignated',
                val,
              )}})],1):_vm._e(),_vm._v(" "),(_vm.isArticle9 && _vm.sfdrStrategy.isIndexDesignated)?_c('div',[_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"ix-id":"sustainablyAlignedIndexExplanation","title":_vm.$t('sustainablyAlignedIndexExplanation.title'),"is-mandatory":false}}),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_vm.sfdrStrategy.sustainablyAlignedIndexExplanation},on:{"input":(val) =>
                _vm.$emit(
                  'updateStrategy',
                  _vm.sfdrStrategy.id,
                  'sustainablyAlignedIndexExplanation',
                  val,
                )}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"ix-id":"investmentAlignedIndexExplanation","title":_vm.$t('investmentAlignedIndexExplanation.title'),"is-mandatory":false}}),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_vm.sfdrStrategy.investmentAlignedIndexExplanation},on:{"input":(val) =>
                _vm.$emit(
                  'updateStrategy',
                  _vm.sfdrStrategy.id,
                  'investmentAlignedIndexExplanation',
                  val,
                )}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"ix-id":"broadMarketIndexComparison","title":_vm.$t('broadMarketIndexComparison.title'),"is-mandatory":false}}),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_vm.sfdrStrategy.broadMarketIndexComparison},on:{"input":(val) =>
                _vm.$emit(
                  'updateStrategy',
                  _vm.sfdrStrategy.id,
                  'broadMarketIndexComparison',
                  val,
                )}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"ix-id":"indexCalculationMethodology","title":_vm.$t('indexCalculationMethodology.title'),"is-mandatory":false}}),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_vm.sfdrStrategy.indexCalculationMethodology},on:{"input":(val) =>
                _vm.$emit(
                  'updateStrategy',
                  _vm.sfdrStrategy.id,
                  'indexCalculationMethodology',
                  val,
                )}})],1)],1):_vm._e(),_vm._v(" "),(_vm.sfdrStrategy.isEnvironmentalGoal)?_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"ix-id":"isCo2EmissionGoal","title":_vm.$t('isCo2EmissionGoal.title')}}),_vm._v(" "),_c('ix-form-input-small-options',{attrs:{"options":[
            { value: true, label: _vm.$t('yes') },
            { value: false, label: _vm.$t('no') },
          ],"value":_vm.sfdrStrategy.isCo2EmissionGoal,"multiple":false,"item-key":"value","item-value":"value","item-text":"label"},on:{"input":(val) =>
              _vm.$emit(
                'updateStrategy',
                _vm.sfdrStrategy.id,
                'isCo2EmissionGoal',
                val,
              )}})],1):_vm._e(),_vm._v(" "),(
          _vm.sfdrStrategy.isCo2EmissionGoal && _vm.sfdrStrategy.isEnvironmentalGoal
        )?_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"ix-id":"mandatoryBenchmark","title":_vm.$t('mandatoryBenchmark.title'),"subtitle":_vm.$t('mandatoryBenchmark.subtitle'),"is-mandatory":true},scopedSlots:_vm._u([{key:"tooltipComponent",fn:function(){return [_c('sfdr-sustainability-benchmark-template')]},proxy:true}],null,false,765165214)}),_vm._v(" "),_c('ix-textarea',{attrs:{"value":_vm.sfdrStrategy.benchmarkDescription},on:{"input":(val) =>
              _vm.$emit(
                'updateStrategy',
                _vm.sfdrStrategy.id,
                'benchmarkDescription',
                val,
              )}})],1):_vm._e(),_vm._v(" "),(_vm.isMitigationOrAdaptationGoal)?_c('v-col',{attrs:{"cols":"12"}},[_c('ix-form-input-question',{attrs:{"ix-id":"doCheckEuTaxonomyAlignment","title":_vm.$t('doCheckEuTaxonomyAlignment.title'),"subtitle":_vm.$t('doCheckEuTaxonomyAlignment.subtitle')}}),_vm._v(" "),_c('ix-form-input-small-options',{attrs:{"options":[
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ],"value":_vm.sfdrStrategy.doCheckEuTaxonomyAlignment,"multiple":false,"item-key":"value","item-value":"value","item-text":"label"},on:{"input":(val) =>
              _vm.$emit(
                'updateStrategy',
                _vm.sfdrStrategy.id,
                'doCheckEuTaxonomyAlignment',
                val,
              )}})],1):_vm._e()],1):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }